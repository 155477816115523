<template>
  <div class="mt-8">
    <div class="flex items-start p-3 my-5 bg-red-200 rounded-md" v-if="errorMessage">
      <div class="text-sm font-medium text-red-500">{{ errorMessage }}</div>
    </div>
    <form class="mt-8" @submit.prevent="createUser">
      <div class="flex flex-wrap -mx-3">
        <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
          <label class="text-xs font-medium text-gray-type-3" for="first-name"
            >First name</label
          >
          <input
            v-model="$v.form.fname.$model"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
            type="text"
            id="first-name"
          />
          <span
            class="text-xs font-bold text-red-type-1"
            v-if="$v.form.fname.required.$invalid"
            >Please enter first name</span
          >
        </div>
        <div class="w-full px-3 md:w-1/2">
          <label class="text-xs font-medium text-gray-type-3" for="last-name"
            >Last name</label
          >
          <input
            v-model="$v.form.lname.$model"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
            type="text"
            id="last-name"
          />
          <span
            class="text-xs font-bold text-red-type-1"
            v-if="$v.form.lname.required.$invalid"
            >Please enter last name</span
          >
        </div>
      </div>
      <div class="mt-6">
        <label class="text-xs font-medium text-gray-type-3" for="email"
          >Email address</label
        >
        <div class="mt-2">
          <input
            v-model="$v.form.email.$model"
            type="email"
            id="email"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
          />
          <span
            class="text-xs font-bold text-red-type-1"
            v-if="$v.form.email.required.$invalid"
            >Please enter email address</span
          >
        </div>
      </div>
      <div class="mt-6">
        <label class="text-xs font-medium text-gray-type-3" for="phone-number"
          >Phone number</label
        >
        <div class="mt-2">
          <input
            v-model="$v.form.phone.$model"
            type="tel"
            id="phone-number"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
          />
          <div
            class="text-xs font-medium text-red-type-1"
            v-if="$v.form.phone.required.$invalid"
          >
            Please enter the phone number
          </div>
          <div
            class="text-xs font-medium text-red-type-1"
            v-if="
              !$v.form.phone.required.$invalid &&
              $v.form.phone.phoneNumber.$invalid
            "
          >
            Please enter a valid phone number
          </div>
        </div>
      </div>
      <div class="mt-6">
        <label class="text-xs font-medium text-gray-type-3" for="password"
          >Password</label
        >
        <div class="relative mt-2">
          <input
            v-model="$v.form.password.$model"
            :type="showPassword ? 'text' : 'password'"
            id="password"
            class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
          />
          <div
            class="absolute inset-y-0 right-0 flex items-center pr-2 text-xs font-medium cursor-pointer text-green-type-1"
            @click="showPassword = !showPassword"
          >
            {{ showPassword ? "Hide" : "Show" }}
          </div>
        </div>
        <span
          class="text-xs font-medium text-red-type-1"
          v-if="$v.form.password.required.$invalid"
          >Please enter a password</span
        >
      </div>
      <app-button
        type="submit"
        class="justify-center block w-full py-3 mt-10 font-bold text-center text-white rounded bg-green-type-1"
        :loading="processing"
      >
        Add User
      </app-button>
    </form>
  </div>
</template>

<script>
import { required, email } from "@vuelidate/validators";

const phoneNumber = (value) => {
  return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
    value
  );
};

export default {
  data() {
    return {
      processing: false,
      errorMessage: "",
      showPassword: false,
      form: {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        password: "",
      },
    };
  },
  validations() {
    return {
      form: {
        fname: {
          required,
        },
        lname: {
          required,
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
          phoneNumber,
        },
        password: {
          required,
        },
      },
    };
  },
  methods: {
    createUser() {
      this.$v.form.$touch();

      if (this.processing || this.$v.form.$errors.length) {
        return;
      }

      this.processing = true;

      this.errorMessage = null;

      this.$http
        .post("/users", { ...this.form })
        .then(() => {
          this.$toast.success("User added successfully");

          this.emitter.emit("done");
        })
        .catch((err) => {
          if (err.response && err.response.data.message) {
            this.errorMessage = err.response.data.message;
          } else {
            this.errorMessage = "Oops! An error occured, please try again.";
          }
        })
        .finally(() => (this.processing = false));
    },
  },
};
</script>