<template>
  <form ref="batch-user-upload-form">
    <input
      type="file"
      accept=".csv"
      ref="batch-user-upload-file"
      @change="uploadBatchUserFile"
      class="hidden"
      multiple="false"
    />
  </form>
  <div class="mt-10">
    <div class="py-8 rounded bg-gray-type-4">
      <template v-if="processing">
        <div class="flex items-center justify-center">
          <app-loading-indicator></app-loading-indicator>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col items-center justify-center">
          <svg width="42" height="32" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
              <path
                d="M34.97.026H6.385a4.194 4.194 0 00-4.163 4.75.84.84 0 001.26.617c.37-.215.793-.327 1.222-.322h5.986c1.08.003 2.04.69 2.39 1.711l.144.461a4.224 4.224 0 003.99 2.872H36.65c.441 0 .874.116 1.256.336a.841.841 0 001.266-.726V4.23A4.204 4.204 0 0034.97.026z"
                fill="#FFA000"
              />
              <path
                d="M38.758 9.002a4.179 4.179 0 00-2.107-.569H17.214a2.536 2.536 0 01-2.391-1.711l-.143-.46a4.224 4.224 0 00-3.99-2.873H4.704a4.025 4.025 0 00-2.059.543A4.194 4.194 0 00.5 7.592V27.77a4.204 4.204 0 004.204 4.204H36.65a4.204 4.204 0 004.204-4.204V12.637a4.175 4.175 0 00-2.097-3.635z"
                fill="#FFC107"
              />
              <path
                d="M20.61 14c.177 0 .342.089.44.236l4.6 6.76a.536.536 0 01-.44.8h-1.961v2.024a2.64 2.64 0 01-2.472 2.635l-.167.005a2.64 2.64 0 01-2.64-2.64l-.001-2.024h-1.96a.536.536 0 01-.392-.2l-.05-.077a.536.536 0 01.003-.523l4.6-6.76a.528.528 0 01.44-.236z"
                fill="#FFA000"
              />
            </g>
          </svg>
          <div class="mt-4 text-xs font-bold text-gray-type-2">
            Drag and drop CSV file here or
            <a
              href="#"
              class="text-green-type-1"
              @click.prevent="$refs['batch-user-upload-file'].click()"
              >select file</a
            >
          </div>
          <div class="mt-3 font-medium text-gray-type-8 text-xxs">
            We accept CSV alone. Max. 500Kb
          </div>
        </div>
      </template>
    </div>
    <p class="mt-6 text-xs font-medium" style="line-height: 1.583">
      The CSV file should have the following as header in the first row for
      successfull mapping of data:
    </p>
    <div class="flex flex-col mt-8">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="min-w-full divide-y divide-gray-type-7">
              <thead class="border bg-gray-type-14">
                <tr>
                  <td
                    class="px-6 py-3 font-medium border-r border-gray-type-7 last:border-r-0 text-xxs text-gray-type-2"
                  >
                    Email
                  </td>
                  <td
                    class="px-6 py-3 font-medium border-r border-gray-type-7 last:border-r-0 text-xxs text-gray-type-2"
                  >
                    Password
                  </td>
                  <td
                    class="px-6 py-3 font-medium border-r border-gray-type-7 last:border-r-0 text-xxs text-gray-type-2"
                  >
                    Fname
                  </td>
                  <td
                    class="px-6 py-3 font-medium border-r border-gray-type-7 last:border-r-0 text-xxs text-gray-type-2"
                  >
                    Lname
                  </td>
                  <td
                    class="px-6 py-3 font-medium border-r border-gray-type-7 last:border-r-0 text-xxs text-gray-type-2"
                  >
                    Phone
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="py-20 border-r border-gray-type-7 last:border-r-0"
                  ></td>
                  <td
                    class="py-20 border-r border-gray-type-7 last:border-r-0"
                  ></td>
                  <td
                    class="py-20 border-r border-gray-type-7 last:border-r-0"
                  ></td>
                  <td
                    class="py-20 border-r border-gray-type-7 last:border-r-0"
                  ></td>
                  <td
                    class="py-20 border-r border-gray-type-7 last:border-r-0"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Papa from "papaparse";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      processing: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    uploadBatchUserFile() {
      this.processing = true;

      const file = this.$refs["batch-user-upload-file"].files[0];

      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const batchData = results.data.map((datum) => ({
            ...datum,
            corporate_id: this.user.id,
          }));

          this.$http
            .post("/users/batch", JSON.stringify({ items: batchData }))
            .then((res) => {
              const successfullyCreatedStaffs = res.data
                .reverse()
                .filter((datum) => datum.success);

              if (batchData.length > successfullyCreatedStaffs.length) {
                this.$toast.error(
                  "Some of the users were not created because of some data error check for the missing users and correct their informations"
                );
              } else {
                this.$toast.success("Batch users created successfully");
              }

              this.emitter.emit("done");
            })
            .catch((err) => {
              if (err.response && err.response.status === 400) {
                this.$toast.error(
                  "An error occured while creating users check csv file and make sure the format is correct"
                );

                return;
              }

              this.$toast.error("An error occured, please try again.");
            })
            .finally(() => (this.processing = false));
        },
      });
    },
  },
};
</script>