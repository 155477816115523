<template>
  <app-loading-container
    class="w-full px-4 py-6 mb-32 lg:py-16 sm:px-10"
    :loading="loading"
    :loaded="loaded"
    :error-loading="errorLoading"
  >
    <div>
      <csv-download-link
        v-if="loaded"
        :data="users"
        :fields="[
          { label: 'First Name', value: 'fname' },
          { label: 'Last Name', value: 'lname' },
          { label: 'Email', value: 'email' },
          { label: 'Phone', value: 'phone' },
          { label: 'Staff ID', value: 'staff_id' },
          { label: 'Trips per month', value: 'trip_count' },
          { label: 'Joined', value: 'created_at' },
        ]"
        download-name="staffs-metrics.csv"
        class="absolute bottom-0 right-0 mb-6 mr-6"
      >
        <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <circle fill="#20E682" cx="28" cy="28" r="28" />
            <path d="M14 14h28v28H14z" />
            <path
              stroke="#FFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M23.406 26.031L28 30.625l4.594-4.594M28 18.372v12.25"
            />
            <path
              d="M38.5 28.875v7.875a.875.875 0 01-.875.875h-19.25a.875.875 0 01-.875-.875v-7.875"
              stroke="#FFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </csv-download-link>
      <div class="grid w-full gap-8 mt-8 sm:grid-cols-3">
        <div class="px-8 pt-6 pb-12 bg-white rounded-xl">
          <template v-if="loading">
            <div class="animate-pulse">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="mt-6 space-y-4">
                <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
                <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex items-center justify-between">
              <div class="font-bold uppercase text-xxs text-gray-type-8">
                Total users
              </div>
            </div>
            <div class="flex justify-between">
              <div class="mt-6 text-2xl font-bold text-gray-type-2">
                {{ users.length }}
              </div>
            </div>
          </template>
        </div>
        <div class="px-8 pt-6 pb-12 bg-white rounded-xl">
          <template v-if="loading">
            <div class="animate-pulse">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="mt-6 space-y-4">
                <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
                <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex items-center justify-between">
              <div class="font-bold uppercase text-xxs text-gray-type-8">
                Active today
              </div>
            </div>
            <div class="flex justify-between">
              <div class="mt-6 text-2xl font-bold text-gray-type-2">

                <span v-if="loadingActiveUsers">
                  Loading..
                </span>
                <div v-else>
                  <span v-if="errorLoadingActiveUsers">N/A</span>
                  <span v-else>{{ activeUsersCount }}</span>
                </div>

              </div>
            </div>
          </template>
        </div>
        <div class="px-8 pt-6 pb-12 bg-gray-type-1 rounded-xl">
          <div class="font-bold text-gray-type-11 text-xxs">TASKS</div>
          <div class="space-y-4">
            <a
              href="#"
              @click.prevent="showBatchUpdateUsersModal = true"
              class="inline-flex items-center mt-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-3 h-3 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <div class="ml-2 text-xs font-medium text-white underline">
                Batch update your users
              </div>
            </a>
            <a
              href="#"
              @click.prevent="showAddUsersModal = true"
              class="inline-flex items-center mt-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-3 h-3 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <div class="ml-2 text-xs font-medium text-white underline">
                Add users to your account
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div
          class="items-center mt-6 space-y-4 lg:space-y-0 lg:space-x-4 lg:flex"
        >
          <div class="w-full lg:w-3/5">
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-4 h-4 pointer-events-none text-gray-type-3"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                v-model="search"
                type="search"
                class="w-full h-12 px-3 py-4 pl-10 text-xs font-medium bg-white outline-none plachol placeholder-gray-type-3 focus:outline-none"
                placeholder="Search user by name, email or staff ID"
              />
            </div>
          </div>
          <div
            class="items-center flex-1 space-y-4 lg:flex lg:space-x-4 lg:space-y-0"
          >
            <div class="flex-shrink-0 text-xs font-medium text-gray-type-2">
              See users from
            </div>
            <select
              class="w-full h-12 px-3 py-4 text-xs font-medium bg-white outline-none placeholder-gray-type-3 focus:outline-none"
              v-model="params.month"
            >
              <option
                v-for="(month, index) in monthOptions"
                :key="index"
                :value="month.value"
              >
                {{ month.label }}
              </option>
            </select>
            <select
              class="w-full h-12 px-3 py-4 text-xs font-medium bg-white outline-none placeholder-gray-type-3 focus:outline-none"
              v-model="params.year"
            >
              <option
                v-for="(year, index) in yearOptions"
                :key="index"
                :value="year.value"
              >
                {{ year.label }}
              </option>
            </select>
          </div>
        </div>
        <app-table
          :loading="loading"
          :error-loading="errorLoading"
          :items="filteredUsers"
          :fields="usersFields"
          class="mt-2"
        >
          <template v-slot:name="{ item }">
            <router-link
              :to="{ name: 'dashboard.users.view', params: { id: item.id } }"
              class="text-xs font-medium underline text-gray-type-3"
            >
              {{ `${item.fname} ${item.lname}` }}
            </router-link>
          </template>
        </app-table>
      </div>
      <modal :show="showAddUsersModal" size="xl">
        <div class="px-6 py-3">
          <div class="flex items-center justify-between mt-4">
            <button
              aria-label="Close panel"
              class="transition duration-150 ease-in-out text-gray-type-2"
              @click="showAddUsersModal = false"
            >
              <svg
                class="w-4 h-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div class="mx-auto font-bold text-gray-type-2">
              <span v-if="addUsersHasMismatchedFields"
                >Resolve mismatched fields</span
              >
              <span v-else>Add users to your account</span>
            </div>
          </div>
          <template v-if="addUsersHasMismatchedFields">
            <p class="mt-8 text-xs font-medium text-gray-type-2">
              You have three mismatched fields, please select corresponding
              fields for them:
            </p>
            <div class="mt-12 space-y-1 overflow-y-auto h-96">
              <div
                class="flex items-center justify-between px-6 py-2 bg-gray-type-10"
                v-for="(field, index) in mismatchedUserFields"
                :key="index"
              >
                <div class="w-1/2 text-xs font-medium text-gray-type-2">
                  {{ field }}
                </div>
                <select
                  class="w-1/2 h-10 p-2 text-xs font-medium bg-white outline-none text-gray-type-8 placeholder-gray-type-3 focus:outline-none"
                >
                  <option value="-1">Select field</option>
                </select>
              </div>
            </div>
            <button
              type="submit"
              class="block w-full py-3 font-bold text-center text-white rounded bg-green-type-1"
            >
              Add Users
            </button>
          </template>
          <template v-else>
            <div
              class="flex items-center w-full mt-8 border-b border-gray-type-5"
            >
              <a
                href="#"
                @click.prevent="activeAddUserTab = 'single'"
                class="w-full pb-3 text-xs transition hover:text-green-type-1"
                :class="
                  activeAddUserTab === 'single'
                    ? 'border-b border-green-type-1 text-green-type-1 font-bold'
                    : 'font-medium text-gray-type-8'
                "
              >
                Single user
              </a>
              <a
                href="#"
                @click.prevent="activeAddUserTab = 'batch'"
                class="w-full pb-3 text-xs transition hover:text-green-type-1"
                :class="
                  activeAddUserTab === 'batch'
                    ? 'border-b border-green-type-1 text-green-type-1 font-bold'
                    : 'font-medium text-gray-type-8'
                "
              >
                Batch addition
              </a>
            </div>
            <template v-if="activeAddUserTab === 'single'">
              <add-single-user-form></add-single-user-form>
            </template>
            <template v-if="activeAddUserTab === 'batch'">
              <batch-upload-add-users></batch-upload-add-users>
            </template>
          </template>
        </div>
      </modal>
      <modal :show="showBatchUpdateUsersModal" size="lg">
        <div class="px-6 py-3">
          <div class="flex items-center justify-between mt-4">
            <button
              aria-label="Close panel"
              class="transition duration-150 ease-in-out text-gray-type-2"
              @click="showBatchUpdateUsersModal = false"
            >
              <svg
                class="w-4 h-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div class="mx-auto font-bold text-gray-type-2">
              Update your staff details
            </div>
          </div>
          <div class="mt-10">
            <batch-upload-update-users></batch-upload-update-users>
          </div>
        </div>
      </modal>
    </div>
  </app-loading-container>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/Modal";
import AppTable from "@/components/AppTable";
import CsvDownloadLink from "@/components/CsvDownloadLink";
import AddSingleUserForm from "@/components/forms/AddSingleUserForm";
import BatchUploadAddUsers from "@/components/forms/BatchUploadAddUsers";
import BatchUploadUpdateUsers from "@/components/forms/BatchUploadUpdateUsers";

export default {
  components: {
    Modal,
    AppTable,
    CsvDownloadLink,
    AddSingleUserForm,
    BatchUploadAddUsers,
    BatchUploadUpdateUsers
  },
  data() {
    return {
      loadingActiveUsers: false,
      errorLoadingActiveUsers: false,
      loading: false,
      loaded: false,
      errorLoading: false,
      activeUsersCount: 0,
      users: [],
      usersFields: [
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Phone" },
        { key: "staff_id", label: "Staf ID" },
        { key: "trip_count", label: "Trips per month" },
      ],
      search: "",
      params: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      },
      monthOptions: [
        {
          label: "Jan",
          value: 1,
        },
        {
          label: "Feb",
          value: 2,
        },
        {
          label: "Mar",
          value: 3,
        },
        {
          label: "Apr",
          value: 4,
        },
        {
          label: "May",
          value: 5,
        },
        {
          label: "Jun",
          value: 6,
        },
        {
          label: "Jul",
          value: 7,
        },
        {
          label: "Aug",
          value: 8,
        },
        {
          label: "Sept",
          value: 9,
        },
        {
          label: "Oct",
          value: 10,
        },
        {
          label: "Nov",
          value: 11,
        },
        {
          label: "Dec",
          value: 12,
        },
      ],
      startYear: 2019,
      showBatchUpdateUsersModal: false,
      showAddUsersModal: false,
      addUsersHasMismatchedFields: false,
      activeAddUserTab: "single",
      mismatchedUserFields: ["password", "name", "email"],
    };
  },
  created() {
    this.fetchUsers();
    this.activeUsersToday();
  },
  mounted() {
    this.emitter.on("retry", () => {
      this.fetchUsers();
    });
    this.emitter.on("close", () => {
      this.showBatchUpdateUsersModal = this.showAddUsersModal = false;
    });
    this.emitter.on("done", () => {
      this.fetchUsers();

      this.showBatchUpdateUsersModal = this.showAddUsersModal = false;
    });
  },
  watch: {
    "params.month"() {
      this.fetchUsers();
    },
    "params.year"() {
      this.fetchUsers();
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    yearOptions() {
      const options = [];

      for (let i = this.startYear; i <= new Date().getFullYear(); i++) {
        options.push({
          label: i,
          value: i,
        });
      }

      return options;
    },
    filteredUsers() {
      return this.users.filter(
        (user) =>
          user.fname.toLowerCase().includes(this.search.toLowerCase()) ||
          user.lname.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase())
      ).reverse();
    },
  },
  methods: {
        activeUsersToday() {
          this.loadingActiveUsers = true;
          this.errorLoadingActiveUsers = false;
          const activeUsers = new Set();
        this.$http
        .get(`/corporates/${this.user.id}/trips`)
        .then((res) => {
          res.data.forEach((e) => {
            activeUsers.add(e.user_id);
          });

          this.activeUsersCount = activeUsers.size;
        })
        .catch(() => {
          this.errorLoadingActiveUsers = true;
        })
        .finally(() => {
          this.loadingActiveUsers = false;
        });
    },
    fetchUsers() {
      this.loading = true;

      this.errorLoading = this.loaded = false;

      this.$http
        .get(
          `/corporates/${this.user.id}/staff_metrics?${new URLSearchParams(
            this.params
          ).toString()}`
        )
        .then((res) => {
          this.users = res.data;
          this.loaded = true;
        })
        .catch(() => {
          this.errorLoading = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>