<template>
  <form ref="batch-user-upload-form">
    <input
      type="file"
      accept=".csv"
      ref="batch-user-upload-file"
      @change="processFile"
      class="hidden"
      multiple="false"
    />
  </form>
  <form @submit.prevent="processFile">
    <div class="py-8 rounded bg-gray-type-4">
      <template v-if="processing">
        <div class="flex items-center justify-center">
          <app-loading-indicator></app-loading-indicator>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col items-center justify-center">
          <svg width="42" height="32" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="nonzero" fill="none">
              <path
                d="M34.97.026H6.385a4.194 4.194 0 00-4.163 4.75.84.84 0 001.26.617c.37-.215.793-.327 1.222-.322h5.986c1.08.003 2.04.69 2.39 1.711l.144.461a4.224 4.224 0 003.99 2.872H36.65c.441 0 .874.116 1.256.336a.841.841 0 001.266-.726V4.23A4.204 4.204 0 0034.97.026z"
                fill="#FFA000"
              />
              <path
                d="M38.758 9.002a4.179 4.179 0 00-2.107-.569H17.214a2.536 2.536 0 01-2.391-1.711l-.143-.46a4.224 4.224 0 00-3.99-2.873H4.704a4.025 4.025 0 00-2.059.543A4.194 4.194 0 00.5 7.592V27.77a4.204 4.204 0 004.204 4.204H36.65a4.204 4.204 0 004.204-4.204V12.637a4.175 4.175 0 00-2.097-3.635z"
                fill="#FFC107"
              />
              <path
                d="M20.61 14c.177 0 .342.089.44.236l4.6 6.76a.536.536 0 01-.44.8h-1.961v2.024a2.64 2.64 0 01-2.472 2.635l-.167.005a2.64 2.64 0 01-2.64-2.64l-.001-2.024h-1.96a.536.536 0 01-.392-.2l-.05-.077a.536.536 0 01.003-.523l4.6-6.76a.528.528 0 01.44-.236z"
                fill="#FFA000"
              />
            </g>
          </svg>
          <div class="mt-4 text-xs font-bold text-gray-type-2">
            Drag and drop CSV file here or
            <a
              href="#"
              class="text-green-type-1"
              @click.prevent="$refs['batch-user-upload-file'].click()"
              >select file</a
            >
          </div>
          <div class="mt-3 font-medium text-gray-type-8 text-xxs">
            We accept CSV alone. Max. 500Kb
          </div>
        </div>
      </template>
    </div>
    <p class="mt-6 text-xs font-medium" style="line-height: 1.583">
      Batch update allows you to update the details of many of your staff all at
      once. You'll need to upload a valid CSV file filled with your staff info.
      Download a csv template
      <a
        href="#"
        class="underline text-green-type-1"
        @click.prevent="downloadTemplate"
        >here</a
      >
    </p>
    <app-button
      type="submit"
      class="justify-center block w-full py-3 mt-16 font-bold text-center text-white rounded bg-green-type-1"
      :loading="processing"
    >
      Upload And Update Staff
    </app-button>
  </form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      processing: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    processFile() {
      const file = this.$refs["batch-user-upload-file"].files[0];

      if (!file) {
        return;
      }

      this.processing = true;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("corporate_id", this.user.id);

      this.$http
        .post("/corporates/user_upload", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);

          this.$toast.success("Batch users updated successfully");

          this.emitter.emit("done");
        })
        .catch((err) => {
          console.log(err);

          this.$toast.error("An error occured, please try again.");
        })
        .finally(() => (this.processing = false));
    },
    downloadTemplate() {
      let headers = {
        lname: "lname",
        fname: "fname",
        phone: "phone",
        email: "email".replace(/,/g, ""),
        staff_id: "staff_id",
      };

      let itemsFormatted = [
        {
          lname: "Sample",
          fname: "User",
          phone: "09010000000",
          email: "example@company.com",
          staff_id: "STAFF001",
        },
      ];

      let fileTitle = "batch-update-staff-template";

      this.exportToCsv(headers, itemsFormatted, fileTitle);
    },
    convertToCSV(objArray) {
      const array =
        typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      let str = "";

      for (let i = 0; i < array.length; i++) {
        let line = "";
        for (const index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
    exportToCsv(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      const jsonObject = JSON.stringify(items);
      const csv = this.convertToCSV(jsonObject);

      const exportedFilenmae =
        fileTitle + ".csv" || "batch-update-staff-template.csv";

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
};
</script>